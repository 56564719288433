import axios from 'axios'
import { CDPRO_NEW_INVITE, CDPRO_PREFERENCES } from 'config/api/Urls'
import { LoadingIndicator } from 'inter-site-components'
import React, { memo, useEffect, useState } from 'react'
import SvgIcon from 'src/components/UI/MarkdownIcon'

import { Button } from './style'

const iconMap = [
  {
    name: 'CONTA_CORRENTE',
    icon: 'pagamentos/cell-money',
  },
  {
    name: 'CARTAO_CREDITO',
    icon: 'pagamentos/card',
  },
  {
    name: 'CAMBIO',
    icon: 'products/cambio/cambio',
  },
  {
    name: 'CREDITO_CORPORATIVO',
    icon: 'products/credito/credito',
  },
  {
    name: 'CREDITO_IMOBILIARIO',
    icon: 'products/financiamento/imobiliario',
  },
  {
    name: 'FOLHA_PAGAMENTO',
    icon: 'products/banco/relatorios',
  },
  {
    name: 'INVESTIMENTOS',
    icon: 'products/investimentos/home-broker',
  },
  {
    name: 'SEGUROS',
    icon: 'products/seguros/money',
  },
]

type iconMapProps = {
  name: string;
  icon: string;
}

type valuesProps = {
  name: string;
  email: string;
  aceite: string;
  cpf: string;
  cnpj: string;
}

type interestsProps = {
  interesse: never;
  descricao: string;
}

type productsType = {
  values: valuesProps | undefined;
  setValues?: Function;
  setStep: Function;
}

function Products ({ values, setValues, setStep }: productsType) {
  const [ interests, setInterests ] = useState<interestsProps[]>([])
  const [ loading, setLoading ] = useState(false)
  const [ selected, setSelected ] = useState([])

  async function loadInterests () {
    try {
      setLoading(true)
      const response = await axios.get(CDPRO_PREFERENCES)
      setInterests(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setStep('error')
    }
  }

  const toggleProduct = (product: never) => {
    if (selected.includes(product)) {
      const auxSelected = selected
      auxSelected.splice(auxSelected.indexOf(product), 1)
      setSelected([ ...auxSelected ])
    } else {
      setSelected([ ...selected, product ])
    }
  }

  async function handleSubmit () {
    setLoading(true)
    try {
      const params = {
        cpf: values?.cpf,
        cnpj: values?.cnpj,
        email: values?.email,
        interesses: selected,
      }
      await axios.post(CDPRO_NEW_INVITE, params)
      setLoading(false)
      setStep('success')
    } catch (error) {
      setLoading(false)
      setStep('error')
    }
  }

  useEffect(() => {
    loadInterests()
  }, [])

  return (
    <>
      <header className='mb-4'>
        <h3 className='fs-24 lh-30 text-grayscale--500 mb-3'>Conte para a gente!</h3>
        <p className='fs-18 lh-22 text-grayscale--400 mb-0'>O que você procura na Conta Digital PJ do Inter? </p>
      </header>
      <div className='row'>
        {loading && selected.length === 0 && <div className='text-center'><LoadingIndicator size='small' /></div>}
        {
          interests.map((item: interestsProps) => {
            const iconF = iconMap.find((icon: iconMapProps) => icon.name === item.interesse)
            return (
              <div key={item.interesse} data-pj={item.interesse} className='col-5 col-md-4 px-2'>
                <div className={`invitation-request__card ${(selected.includes(item.interesse)) ? 'selected' : ''}`} onClick={() => toggleProduct(item.interesse)}>
                  <div className='icon d-flex align-items-center justify-content-center'>
                    <SvgIcon width='24' height='24' color='orange--base' directory='v2' icon={iconF?.icon} />
                  </div>
                  <p className='fs-14 lh-17 text-center text-grayscale--400 fw-700 mb-0'>{item.descricao}</p>
                </div>
              </div>
            )
          })
        }
      </div>
      <Button
        type='submit'
        active={selected.length > 0 && !loading}
        disabled={selected.length === 0 || loading}
        className='btn bg-orange--base text-white rounded-2 w-100 mw-100 my-3'
        onClick={handleSubmit}
      >
        SOLICITAR CONVITE
      </Button>
      <p className='fs-12 lh-15 text-grayscale--500 text-center'>Ao continuar, você autoriza o Banco Inter a consultar informações cadastrais de créditos e débitos do CNPJ que solicita a abertura de conta corrente.</p>
    </>
  )
}

export default memo<productsType>(Products)

import styled from 'styled-components'

export const Container = styled.div`
  
  .form-label-check {
    &:before {
      top: 50% !important;
      transform: translateY(-50%) !important;
    }

    &:after {
      top: 50% !important;
      transform: translateY(-50%) rotate(45deg) !important;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
`

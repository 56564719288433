import { gray, orange, white } from 'src/styles/colors';
import styled from 'styled-components';

type RadioProps = {
  active?: boolean;
}

type ButtonProps = {
  active?: boolean;
}

export const Form = styled.form`

  input {
    background: ${gray['400']};
    border-radius: 8px;
    border: 0;
    font-size: 14px;
    line-height: 17px;
    padding: 0 10px;
    font-weight: 300;
    color: #8A96A8;
    height: 40px;
  }

  .hasError {
    color: red;
  }

  .formLabelCheck {
    position: relative;
    margin-left: 8px;
    padding-left: 10px;

    &::before, &::after {
      display: block;
      position: absolute;
      content: '';
    }

    &::before {
      border: 2px solid ${gray['500']};
      width: 20px;
      height: 20px;
      left: -30px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 3px;
    }
  }

  .formInputCheck {
    width: auto;
    margin-right: 10px;
    opacity: 0;
    height: 0;
      &:disabled ~ label {
      opacity: 0.6;
    }
    &:checked ~ label {
      &::before {
        border: 2px solid ${orange.base};
        background-color: ${orange.base};
      }
      &::after {
        left: -26px;
        top: 46%;
        width: 6px;
        height: 10px;
        border: solid ${white};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg) translateY(-50%);
      }
    }
  }  
`

export const Text = styled.h3`
  margin-bottom: 35px;
`

export const Radio = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6A6C72;
  margin-bottom: 22px;

  &:focus {
    outline: none;
  }

  &::before, &::after {
    content: '';
    display: block;
  }

  &::before {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid ${(props: RadioProps) => props.active ? '#ff7a00' : '#B6B7BB'};
    margin-right: 19px;
  }

  ${(props: RadioProps) => props.active && `
    &::after {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ff7a00;
      position: absolute;
      left: 4px;
    }
  `}
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  background: ${(props: ButtonProps) => props.active ? '#ff7a00' : '#DEDFE4'};
  border-radius: 8px;
  border: none;
  color: ${(props: ButtonProps) => props.active ? '#ffffff' : '#B6B7BB'};
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-top: 35px;

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #DEDFE4 !important;
    color: #B6B7BB !important;
  }
`

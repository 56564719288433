import React, { useState, useEffect } from 'react'
import SuccessImage from 'src/assets/images/success-image.png'
import ErrorImage from 'src/assets/images/warning-image.png'

import { Button } from './style'

type middleFormSuccessProps = {
  title: string;
  description: string;
  type: string;
  closeModal: Function;
  btnText: string;
}

const Message = ({ type, title, description, closeModal, btnText }: middleFormSuccessProps) => {
  const [ image, setImage ] = useState(SuccessImage)

  useEffect(() => {
      switch (type) {
        case 'success':
          setImage(SuccessImage)
          break
        case 'error':
          setImage(ErrorImage)
          break
        default:
          setImage(SuccessImage)
          break
      }
  }, [ type ])

  return (
    <div className='text-center'>
      <div className='col-12 my-3'>
        <img src={image} alt={title} />
      </div>
      <h3 className='fs-24 lh-30 text-grayscale--500 mt-4'>{title}</h3>
      <p className='fs-18 lh-22 text-grayscale--500'>{description}</p>
      <Button
        className='mt-2'
        active={true}
        onClick={() => closeModal()}
      >
        {btnText}
      </Button>
    </div>
  )
}

export default Message

import React, { useState, ChangeEvent } from 'react'
import { encrypt } from '../../../../../shared/Encryption'
import { useForm } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import { Form, Button } from './style'

type dataProps = {
  name: string;
  email: string;
}

type personalProps = {
  setValues: Function;
  setStep: Function;
  cnpjData: string;
}

function Personal ({ setValues, setStep, cnpjData }: personalProps) {
  const { register, errors, handleSubmit }: Pick<ReturnType<typeof useForm>, 'register' | 'errors' | 'handleSubmit' | 'setValue'> = useForm()
  const [ cpf, setCpf ] = useState('')
  const [ loading, setLoading ] = useState(false)

  const [ nome, setNome ] = useState('')
  const [ email, setEmail ] = useState('')

  async function sendForm (data: dataProps) {
    setLoading(true)
    const { name, email }: dataProps = data
    const cpfPromise = await encrypt(cpf.replace(/[^0-9]+/g, ''))
    const cnpjPromise = await encrypt(cnpjData.replace(/[^0-9]+/g, ''))
    const emailPromise = await encrypt(email)

    setValues({
      name,
      email: emailPromise,
      aceite: true,
      cpf: cpfPromise,
      cnpj: cnpjPromise,
    })
    setLoading(false)
    setStep('products')
  }

  return (
    <>
      <header className='mb-4'>
        <h3 className='fs-24 lh-30 text-grayscale--500 fw-600 mb-3'>Dados pessoais</h3>
        <p className='fs-18 lh-22 text-grayscale--400 fw-400 mb-0'>Já estamos quase lá! Mas antes, para sua segurança, precisamos apenas de mais algumas informações.</p>
      </header>
      <Form
        name='invitation-request-cdpro'
        method='post'
        className='solicite-contato form-hooks'
        onSubmit={handleSubmit(sendForm)}
      >
        <div className='row'>

          <div className='col-12 d-flex flex-column pb-3'>
            <label htmlFor='nome' className='fs-14 lh-16 fw-400'>Nome</label>
            <input
              ref={register({
              required: 'Nome obrigatório',
              validate: {
                isNome: (value: string) => value.length >= 3 || 'Digite seu nome completo',
              },
            })}
              name='nome'
              id='nome'
              type='text'
              placeholder='Digite seu nome completo'
              onChange={(event: ChangeEvent<HTMLInputElement>) => setNome(event.target.value)}
            />
            { errors.nome && <p className='fs-12 pt-1'>{errors.nome.message}</p> }
          </div>

          <div className='col-12 d-flex flex-column pb-3'>
            <label htmlFor='email' className='fs-14 lh-16 fw-400'>E-mail</label>
            <input
              ref={register({
              required: 'E-mail obrigatório',
            })}
              name='email'
              id='email'
              type='text'
              placeholder='Digite seu email'
              onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
            />
            { errors.email && <p className='fs-12 pt-1'>{errors.email.message}</p> }
          </div>

          <div className='col-12 d-flex flex-column pb-3'>
            <label htmlFor='cpf' className='fs-14 lh-16 fw-400'>CPF</label>
            <input
              ref={register({
              required: 'CPF obrigatório',
            })}
              name='cpf'
              id='cpf'
              type='text'
              placeholder='Informe o CPF'
              value={cpf}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setCpf(Masks.MaskCPF(event.currentTarget.value))}
            />
            { errors.cnpj && <p className='fs-12 pt-1'>{errors.cnpj.message}</p> }
          </div>

        </div>

        <Button
          type='submit'
          className='my-3'
          active={(nome !== '' && email !== '' && cpf !== '') ? true : false}
          disabled={(nome === '' && email === '' && cpf === '') ? true : false}
        >
          {loading ? 'Enviando...' : 'ENVIAR PARA ANÁLISE'}
        </Button>
      </Form>
    </>
  )
}

export default Personal

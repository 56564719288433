import React, { useState, ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'

import { Form, Button } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type IAccountForm = {
  setStep: Function;
  cnpj?: string;
  classTitle?: string;
  classDescription?: string;
  dataLayer?: IDataLayerParams;
}

const Analyze = ({ setStep, cnpj, classTitle, classDescription, dataLayer }: IAccountForm) => {
  const { register, errors, handleSubmit }: Pick<ReturnType<typeof useForm>, 'register' | 'errors' | 'handleSubmit' | 'setValue'> = useForm()
  const [ acceptedTerms, setAcceptedTerms ] = useState(false)
  const [ value, setValue ] = useState(Masks.MaskCNPJ(cnpj || ''))
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const sendForm = async (data: Record<string, string>) => {
    setLoading(true)
    sendDatalayerEvent({
      ...dataLayer,
      section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
      section_name: dataLayer ? dataLayer.section_name : '',
      element_name: 'SOLICITAR ANÁLISE',
      element_action: 'click button',
      element_previous: dataLayer ? dataLayer.element_name + ' ' + dataLayer.section : '',
      redirect_url: `https://conta-digital-pj.bancointer.com.br/index.html?NOVO-CNPJ=${data.cnpj.replace(/\D/g, '')}`,
      step: 'success',
    })

    window.location.href = `https://conta-digital-pj.bancointer.com.br/index.html?NOVO-CNPJ=${data.cnpj.replace(/\D/g, '')}`
  }

  function handleCheck (event: ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAcceptedTerms(inputValue)
  }

  return (
    <>
      <header className='row justify-content-center '>
        <div className='col-12'>
          <h4 className={`fs-20 text-grayscale--500 text-center mb-3 ${classTitle}`}>Solicite sua análise agora</h4>
        </div>
      </header>
      <div className='custom-form mb-3'>
        <p className={`fs-16 text-grayscale--500 text-left text-md-center ${classDescription}`}>Nesse pré-cadastro, fazemos a análise da sua abertura de conta. É bem rapidinho.</p>
      </div>
      <Form
        name='subscribeForm'
        method='post'
        className='solicite-contato form-hooks'
        onSubmit={handleSubmit(sendForm)}
      >
        <div className='body-form'>
          <div className='col-12 d-flex flex-column px-0'>
            <label htmlFor='cnpj' className='fs-14 lh-16 fw-400'>CNPJ</label>
            <input
              ref={register({
                required: 'CNPJ obrigatório',
                validate: {
                  isCnpj: (value: string) => Validations.cnpj(value) || 'CNPJ Inválido',
                },
              })}
              name='cnpj'
              id='cnpj'
              type='tel'
              placeholder='Informe o CNPJ'
              value={value}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(Masks.MaskCNPJ(event.currentTarget.value))}
            />
            { errors.cnpj && <p className='fs-12 pt-1'>{errors.cnpj.message}</p> }
          </div>
          <div className='col-12 d-flex pl-0 mt-4 align-items-center'>
            <input
              ref={register()}
              className='formInputCheck'
              id='aceite'
              name='Aceite'
              type='checkbox'
              onChange={handleCheck}
            />
            <label className='formLabelCheck fs-14 lh-16 text-gray-200' htmlFor='aceite'>
              Li e concordo com os Termos e Condições de
              <a
                className='fw-700'
                title='Acessar Política de Privacidade do Banco Inter'
                href='https://marketing.bancointer.com.br/comunicacao/pdf/abertura-conta-cdpro/condicoes_cdpj.pdf'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: 'Abertura de Conta',
                    section_name: 'null',
                    redirect_url: 'https://marketing.bancointer.com.br/comunicacao/pdf/abertura-conta-cdpro/condicoes_cdpj.pdf',
                  })
                }}
              > Abertura de Conta
              </a>, com o Termos e Condições de
              <a
                className='fw-700'
                title='Acessar Política de Privacidade do Banco Inter'
                href='https://marketing.bancointer.com.br/comunicacao/pdf/abertura-conta-cdpro/termos_super_app.pdf'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: 'Uso Super App Inter',
                    section_name: 'null',
                    redirect_url: 'https://marketing.bancointer.com.br/comunicacao/pdf/abertura-conta-cdpro/termos_super_app.pdf',
                  })
                }}
              > Uso Super App Inter
              </a> e com a
              <a
                className='fw-700'
                title='Acessar Política de Privacidade do Banco Inter'
                href='https://inter.co/politica-de-privacidade/privacidade'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: 'Política de Privacidade do Inter',
                    section_name: 'null',
                    redirect_url: 'https://inter.co/politica-de-privacidade/privacidade',
                  })
                }}
              > Política de Privacidade do Inter
              </a>.
            </label>
          </div>
          <div className='col-12 mx-auto mt-4 px-0 text-center'>
            <Button
              type='submit'
              title='Enviar'
              active={acceptedTerms && !loading}
              disabled={!acceptedTerms || loading}
            >
              {loading ? 'Enviando...' : 'SOLICITAR ANÁLISE'}
            </Button>
            <label className='fs-16 lh-20 text-grayscale--500 mt-5'>Já começou seu cadastro e tem uma senha</label>
            <button type='button' className='btn bg-transparent text-orange--extra fs-16 lh-20 fw-600' onClick={() => setStep('retorno')}>CONTINUAR CADASTRO</button>
          </div>
        </div>
      </Form>
    </>
  )
}

export default Analyze

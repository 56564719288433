
import * as acm from './acm.js'

export const encrypt = (payload) => {
  return new Promise((resolve, reject) => {
  import('jsencrypt').then(({ JSEncrypt }) => {
    const jsencrypt = new JSEncrypt()

    jsencrypt.setPublicKey(acm.publicKey)
    const result = resolve(jsencrypt.encrypt(payload))
    return result
  })
  })
}

import React, { memo, useState, useEffect } from 'react'
import { translate } from 'react-i18next'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import Personal from './Steps/Personal'
import Products from './Steps/Products'
import Message from './Steps/Message'
import Analyze from './Steps/Analyze'
import AnalyzeReturn from './Steps/AnalyzeReturn'
import AccountPfForm from './Steps/_AccountPfForm/AccountPfForm'
import Question from './Steps/question'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import './OpenAccountPJForm.scss'

type openAccountPJFormType = {
  closeModal: Function;
  cnpj?: string | undefined;
  classTitle?: string;
  classDescription?: string;
  dataLayer?: IDataLayerParams;
}

type valuesProps = {
  name: string;
  email: string;
  aceite: string;
  cpf: string;
  cnpj: string;
}

function OpenAccountPJForm ({ closeModal, cnpj, classTitle, classDescription, dataLayer }: openAccountPJFormType) {
  const [ step, setStep ] = useState('fluxo')
  const [ success, setSuccess ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ values, setValues ] = useState<valuesProps | undefined>()
  const [ data, setData ] = useState('')

  let body = null

  function resetModal () {
    if (closeModal) {
      closeModal()
    }
    setStep('fluxo')
  }

  useEffect(() => {
    setSuccess(false)
    setError(false)
  }, [ step ])

  switch (step) {
    case 'fluxo':
      body = (
        <Analyze
          setStep={setStep}
          cnpj={cnpj}
          classTitle={classTitle}
          classDescription={classDescription}
          dataLayer={dataLayer}
        />
      )
      break
    case 'retorno':
      body = (
        <AnalyzeReturn
          cnpj={cnpj}
          dataLayer={dataLayer}
        />
      )
      break
    case 'personal':
      body = (
        <Personal
          setValues={setValues}
          setStep={setStep}
          cnpjData={data}
        />
      )
      break
    case 'products':
      body = (
        <Products
          values={values}
          setValues={setValues}
          setStep={setStep}
        />
      )
      break
    case 'download':
      body = <AccountPfForm customOrigin='site-institucional:/empresas/conta-digital/pessoa-juridica/' />
      break
    case 'success':
      body = (
        <Message
          type='success'
          title='Recebemos sua solicitação'
          description='Em breve, você vai receber a confirmação no e-mail cadastrado na sua Conta Digital Pessoa Física.'
          closeModal={() => resetModal}
          btnText='FECHAR'
        />
      )
      break
    case 'error':
      body = (
        <Message
          type='error'
          title='Não foi possível solicitar sua conta.'
          description='Para abrir uma Conta Digital Pessoa Jurídica você precisa ser sócio ou procurador da empresa.'
          closeModal={() => resetModal}
          btnText='ENTENDI'
        />
      )
      break
    case 'partner':
      body = (
        <Question
          answer='Você é sócio da empresa ou procurador?'
          setStep={setStep}
          yes='hasAccount'
          not='error'
          setSuccess={setSuccess}
          success={success}
          setError={setError}
          error={error}
        />
      )
      break
    case 'hasAccount':
      body = (
        <Question
          answer='Você já possui uma Conta Digital Pessoa Física no Inter?'
          setStep={setStep}
          yes='personal'
          not='download'
          setSuccess={setSuccess}
          success={success}
          setError={setError}
          error={error}
        />
      )
  }

  return (
    <div className={closeModal ? 'invitation-request' : ''}>
      <button
        className='invitation-request__button border-0 bg-transparent'
        onClick={() => resetModal}
      >
        {closeModal ? <Close width='24' height='24' color='grayscale-500' /> : '' }
      </button>
      {body}
    </div>
  )
}

export default translate('Components')(memo(OpenAccountPJForm))

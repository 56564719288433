import React, { useState } from 'react'

// styles
import { Radio, Button, Text } from './style'

// types
type QuestionProps = {
  answer: string;
  setStep: Function;
  yes: string;
  not: string;
  success: boolean;
  setSuccess: Function;
  error: boolean;
  setError: Function;
}

const Question = ({ answer, setStep, yes, not, success, setSuccess, error, setError }: QuestionProps) => {
  const [ flow, setFlow ] = useState(false)

  const handleSuccess = () => {
    setError(false)
    setSuccess(!success)
    setFlow(true)
  }

  const handleError = () => {
    setSuccess(false)
    setError(!error)
    setFlow(false)
  }

  return (
    <div>
      <Text className='fs-24 lh-30 text-grayscale--500'>{answer}</Text>
      <Radio
        active={success}
        onClick={() => handleSuccess()}
      >
        Sim
      </Radio>
      <Radio
        active={error}
        onClick={() => handleError()}
      >
        Não
      </Radio>

      <Button
        active={success || error}
        disabled={!success && !error}
        onClick={() => setStep(flow ? yes : not)}
      >
        CONTINUAR
      </Button>

    </div>
  )
}

export default Question
